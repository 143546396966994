<template>
  <div class="case">
    <div class="bg">
      <div class="info">
        <p>应用案例</p>
        <p>Application case</p>
      </div>
    </div>
    <ul class="caselist">
      <li @click="gocaseinfo_kyl">
        <div class="pic"><img src="../../assets/image/cases/case_bj(1).png" alt=""></div>
        <p>昆阳路越江及配套道路工程</p>
      </li>
      <li @click="gocaseinfo_gtdt">
        <div class="pic"><img src="../../assets/image/cases/case_bj(2)_gtdt3.png" alt=""></div>
        <p>高铁地铁项目精装修</p>
      </li>
      <li @click="gocaseinfo_sd">
        <div class="pic"><img src="../../assets/image/cases/case_bj(3)_sd2.png" alt=""></div>
        <p>车行山体隧道</p>
      </li>
      <li @click="gocaseinfo_dt">
        <div class="pic"><img src="../../assets/image/cases/case_bj(4)_dt.png" alt=""></div>
        <p>地铁项目</p>
      </li>
      <li @click="gocaseinfo_ws">
      <div class="pic"><img src="../../assets/image/cases/case_bj(5)_ws2.jpg" alt=""></div>
      <p>污水处理厂项目</p>
      </li>
      <li @click="gocaseinfo_newgd">
      <div class="pic"><img src="../../assets/image/cases/case_bj(6)_newgd.png" alt=""></div>
      <p>现代有轨电车项目</p>
      </li>

    </ul>
    <!-- <div class="seemore" @click="contactus">联系我们，查看更多 <i class="el-icon-right"></i></div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    gocaseinfo_newgd () {
      this.$router.push('caseinfo_newgd')
    },
    gocaseinfo_ws () {
      this.$router.push('caseinfo_ws')
    },
    gocaseinfo_dt () {
      this.$router.push('caseinfo_dt')
    },
    gocaseinfo_sd () {
      this.$router.push('caseinfo_sd')
    },
    gocaseinfo_gtdt () {
      this.$router.push('caseinfo_gtdt')
    },
    gocaseinfo_kyl () {
      this.$router.push('caseinfo_kyl')
    },
    gocaseinfo () {
      this.$router.push('caseinfo')
      // ''
    },
    contactus () {
      // this.$router.push('contactus')
      window.open('https://wj.qq.com/s2/10302697/55cd/', '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.case{
  .bg{
    background: url("../../assets/image/cases/case_bg (1).png") no-repeat;
    background-size: cover;
    height: 60vh;
    position: relative;
    .info{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
      text-align: center;
      p:first-child{
        font-size: 50px;
        font-weight: 700;
        line-height: 40px;
        font-family: "siyuan";
      }
      p:last-child{
        margin-top: 20px;
        font-size: 30px;
      }
    }
  }
  .caselist{
    max-width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    padding: 50px 0;
    li{
      width: 31%;
      margin-right: 1%;
      float: left;
      margin-top: 50px;
      // height: 290px;
      text-align: center;
      background-color: #fff;
      box-shadow: -1px 0 10px rgba(0, 0, 0, 0.05), 0 -1px 10px rgba(0, 0, 0, 0.05), 0 1px 10px rgba(0, 0, 0, 0.05), 1px 0 10px rgba(0, 0, 0, 0.05);
      @media screen and (max-width:600px) {
        width: 80%;
        margin: 15px 10%;
      }
      .pic{
        width: 100%;
        height: 218px;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      p{
        line-height: 40px;
      }
    }
     li:nth-child(3n+3){
      margin-right: 0%;
    }
  }
  .seemore{
    margin: 0 auto;
    text-align: center;
    margin: 20px 0 50px;
    color: #4E92DB;
    cursor: pointer;
  }
}
</style>
