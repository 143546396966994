var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case"},[_vm._m(0),_c('ul',{staticClass:"caselist"},[_c('li',{on:{"click":_vm.gocaseinfo_kyl}},[_vm._m(1),_c('p',[_vm._v("昆阳路越江及配套道路工程")])]),_c('li',{on:{"click":_vm.gocaseinfo_gtdt}},[_vm._m(2),_c('p',[_vm._v("高铁地铁项目精装修")])]),_c('li',{on:{"click":_vm.gocaseinfo_sd}},[_vm._m(3),_c('p',[_vm._v("车行山体隧道")])]),_c('li',{on:{"click":_vm.gocaseinfo_dt}},[_vm._m(4),_c('p',[_vm._v("地铁项目")])]),_c('li',{on:{"click":_vm.gocaseinfo_ws}},[_vm._m(5),_c('p',[_vm._v("污水处理厂项目")])]),_c('li',{on:{"click":_vm.gocaseinfo_newgd}},[_vm._m(6),_c('p',[_vm._v("现代有轨电车项目")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('div',{staticClass:"info"},[_c('p',[_vm._v("应用案例")]),_c('p',[_vm._v("Application case")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../../assets/image/cases/case_bj(1).png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../../assets/image/cases/case_bj(2)_gtdt3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../../assets/image/cases/case_bj(3)_sd2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../../assets/image/cases/case_bj(4)_dt.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../../assets/image/cases/case_bj(5)_ws2.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../../assets/image/cases/case_bj(6)_newgd.png"),"alt":""}})])
}]

export { render, staticRenderFns }